<template>
  <v-app-bar
    color="white color1--text"
    app
    clipped-left
    fixed
    elevate-on-scroll
  >
    <v-app-bar-nav-icon
      id="menu-hamburger"
      class="no-hover"
      color="color1--text" @click.stop="$emit('toggle-drawer')"
    ></v-app-bar-nav-icon>
    <router-link
      v-if="logoUrl"
      to="/"
      tag="img"
      :src="logoUrl"
      :style="`height: ${headHeight}px; max-height: ${headHeight}px; position: relative; top: ${headHeight === 56 ? 3 : 25}px;`"
    >{{logoUrl}}</router-link>

    <v-spacer></v-spacer>

    <!-- CART FAB  -->
    <cart-fab></cart-fab>
    <!-- USER -->
    <template v-if="user">
      <!-- NOTIFICATIONS -->
      <v-fab-transition>
        <notifications v-if="notifications && notifications.length"/>
      </v-fab-transition>
      <!-- USER NAME  -->
      <user-menu color="color1"></user-menu>
    </template>
    <template v-else>
      <v-btn text color="color1" @click.stop="$emit('login-click')">
        Sign In
      </v-btn>
    </template>

    <template v-slot:extension v-if="$vuetify.breakpoint.smAndUp">
      <v-spacer></v-spacer>
      <v-btn
        color="color1"
        text tile
        :to="{name: 'member-search'}"
      >
        Members
      </v-btn>
      <v-btn
        v-if="!(user && user.member)"
        color="color1"
        text tile
        :to="{name: 'member-levels'}"
      >
        Join
      </v-btn>
      <v-btn
        color="color1"
        text tile
        :to="{name: 'filtered-tournaments', params: {filter: 'upcoming'}}"
      >
        tournaments
      </v-btn>
      <v-btn
        color="color1"
        text tile
        :to="{name: 'order-lookup'}"
      >
        Order lookup
      </v-btn>
    </template>

  </v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex'
const CartFab = () => import('@/components/Utils/CartFab')
const UserMenu = () => import('@/Nav/Toolbars/UserMenu')

export default {
  data () {
    return {
      drawer: null,
      signInDialog: false
    }
  },
  computed: {
    ...mapGetters([
      'user',
      'cart',
      'organizationName',
      'logoUrl'
    ]),
    userMenu () {
      const me = this.user.member ? { name: 'member-profile', params: this.user.member } : { name: 'me' }
      return [
        { icon: 'fas fa-user-circle', text: 'My Account', to: me },
        { icon: 'fas fa-history', text: 'My Orders', to: { name: 'my-orders' } },
        { icon: 'fas fa-heart', text: 'My Favorites', to: { name: 'my-bookmarks' } },
        { icon: 'fas fa-sign-out-alt', text: 'Sign Out', click: () => { this.$emit('logout-click') } }
      ]
    },
    headHeight () {
      return this.$vuetify.breakpoint.smAndDown ? 56 : 112
    }
  },
  methods: {
    goToCart () {
      this.$router.push({ name: 'checkout' })
    }
  },
  components: {
    CartFab,
    UserMenu
  }
}
</script>
<style>
.noW {
  padding: 0;
  min-width: 0;
}
.p1440 a, .p1440 button {
  text-transform: uppercase;
  text-decoration: none;
  border-bottom: 1px solid white;
}
.p1440 a:not(.no-hover):hover, .p1440 button:not(.no-hover):hover {
  border-bottom: 1px solid #ee4640;
}
.p1440 .v-btn--active:before, .p1440 .v-btn:hover:before, .p1440 .v-btn:focus:before {
  background-color: white !important;
}
.app_bar_img {
  width: 100%;
  transition: width 200ms
}
.v-app-bar--is-scrolled .app_bar_img {
  width: 200px
}
.v-app-bar.v-app-bar--hide-shadow {
  border-bottom: 1px solid #dcddde !important;
}
</style>
